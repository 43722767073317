import {createBrowserRouter} from "react-router-dom";

import HomeScreen from "./page/home";
import SmartCityScreen from "./page/smartcity";
import JiashiFormScreen from "./page/jiashiform";
import CustomDevelopmentScreen from "./page/customdevelopment";
import ContactUsScreen from "./page/contactus";



const router = createBrowserRouter([
    {
        path: "/",
        element: <HomeScreen/>,
    },
    {
        path: "smartCity",
        element: <SmartCityScreen/>,
    },
    {
        path: "jiashiform",
        element: <JiashiFormScreen/>
    },
    {
        path: "customdevelopment",
        element: <CustomDevelopmentScreen/>,
    },
    {
        path: "contactus",
        element: <ContactUsScreen/>,
    }
]);


export default router


