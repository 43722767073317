import React, {useState} from 'react';
import homeStyle from "./index.module.css";
import {Link, NavLink} from "react-router-dom";

export default function HomeScreen() {
    const [selectedItem, setSelectedItem] = useState('首页')

    // 导航条
    function HeaderNavbar() {
        return (
            <nav className={homeStyle.headerNavbar}>
                <div className={homeStyle.logo}>
                    <img src={require('../../images/logo.png')}/>
                    <h3>嘉世工作室</h3>
                </div>
                <div className={homeStyle.navbarItems}>
                    <ul>
                        <NavLink to={"/"}>
                            <li onClick={() => setSelectedItem("首页")}
                                className={selectedItem === "首页" ? homeStyle.active : ""}>首页
                            </li>
                        </NavLink>
                        <NavLink to={"/smartCity"}>
                            <li onClick={() => setSelectedItem("智慧城市")}
                                className={selectedItem === "智慧城市" ? homeStyle.active : ""}>智慧城市
                            </li>
                        </NavLink>
                        <NavLink to={"/jiashiform"}>
                            <li onClick={() => setSelectedItem("嘉世表单")}
                                className={selectedItem === "嘉世表单" ? homeStyle.active : ""}>嘉世表单
                            </li>
                        </NavLink>
                        <NavLink to={"/customdevelopment"}>
                            <li onClick={() => setSelectedItem("开发定制")}
                                className={selectedItem === "开发定制" ? homeStyle.active : ""}>开发定制
                            </li>
                        </NavLink>
                        <NavLink to={"/contactus"}>
                            <li onClick={() => setSelectedItem("关于我们")}
                                className={selectedItem === "关于我们" ? homeStyle.active : ""}>关于我们
                            </li>
                        </NavLink>

                    </ul>
                </div>
            </nav>
        )
    }

    // 轮播图
    function Rotation() {
        return (
            <div className={homeStyle.rotationBox}>
                <img className={homeStyle.rotation} alt={'图片'} src={require('../../images/swiper.png')}/>
            </div>
        )
    }

    // 解决方案
    function Solution() {
        return (
            <div className={homeStyle.solutionBox}>
                <div className={homeStyle.solutionTitle}>
                    <h1>解决方案</h1>
                    <h4>为客户提供专业的软件整体解决方案</h4>
                </div>
                <div className={homeStyle.solutionList}>
                    <div className={homeStyle.cardBox}>
                        <div className={homeStyle.card}>
                            <b className={homeStyle.b1}></b>
                            <div className={homeStyle.cardContent}>
                                <h4>行业软件定制</h4>
                                <p>进销存,企业erp,订单管理,档案管理,商超系统,</p>
                            </div>
                            <div className={homeStyle.cardFooter} style={{backgroundColor: '#f97b25'}}>
                                <a>查看详情</a>
                            </div>
                        </div>
                    </div>
                    <div className={homeStyle.cardBox}>
                        <div className={homeStyle.card}>
                            <b className={homeStyle.b2}></b>
                            <div className={homeStyle.cardContent}>
                                <h4>app,小程序开发定制</h4>
                                <p>Android、IOS,微信小程序,支付宝小程序,以及与现有系统对接扩展</p>
                            </div>
                            <div className={homeStyle.cardFooter} style={{backgroundColor: '#a0d028'}}>
                                <a>查看详情</a>
                            </div>
                        </div>
                    </div>
                    <div className={homeStyle.cardBox}>
                        <div className={homeStyle.card}>
                            <b className={homeStyle.b3}></b>
                            <div className={homeStyle.cardContent}>
                                <h4>工业软件定制</h4>
                                <p>上位机控制系统,大数据展示屏等开发</p>
                            </div>
                            <div className={homeStyle.cardFooter} style={{backgroundColor: '#5e98ae'}}>
                                <a>查看详情</a>
                            </div>
                        </div>
                    </div>
                    <div className={homeStyle.cardBox}>
                        <div className={homeStyle.card}>
                            <b className={homeStyle.b4}></b>
                            <div className={homeStyle.cardContent}>
                                <h4>智慧城市</h4>
                                <p>集成模块：商超配送,信息流转,商家服务等模块陆续开发中</p>
                            </div>
                            <div className={homeStyle.cardFooter} style={{backgroundColor: '#49b789'}}>
                                <a>查看详情</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={homeStyle.solutionMore}>
                    <a>查看更多解决方案</a>
                </div>
            </div>
        )
    }

    //服务流程
    function ServiceProcess() {
        return (
            <div className={homeStyle.serviceProcess}>
                <div>
                    <div>
                        <div className={homeStyle.serviceProcessTitle}>
                            <h2>服务流程</h2>
                            <p>放大品牌的声音，赋予品牌弥久传动力</p>
                        </div>
                        <div>
                            <div className={homeStyle.serviceProcessContent}>
                                <ul>
                                    <li>
                                        <a>
                                            <div className={homeStyle.IconBox}>
                                                <div className={homeStyle.circle}></div>
                                                <div className={homeStyle.Icon}></div>
                                            </div>
                                            <h3>需求沟通</h3>
                                            <p>倾听客户需求，了解用户使用环境和操作流程</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <div className={homeStyle.IconBox}>
                                                <div className={homeStyle.circle}></div>
                                                <div className={`${homeStyle.Icon} ${homeStyle.Icon2}`}></div>
                                            </div>
                                            <h3>签订合同</h3>
                                            <p>头脑风景交互情景模拟，原型设计</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <div className={homeStyle.IconBox}>
                                                <div className={homeStyle.circle}></div>
                                                <div className={`${homeStyle.Icon} ${homeStyle.Icon3}`}></div>
                                            </div>
                                            <h3>交互设计</h3>
                                            <p>头脑风景交互情景模拟，原型设计</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <div className={homeStyle.IconBox}>
                                                <div className={homeStyle.circle}></div>
                                                <div className={`${homeStyle.Icon} ${homeStyle.Icon4}`}></div>
                                            </div>
                                            <h3>视觉创意</h3>
                                            <p>色彩及平面元素设定，结构和布局规范确认</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <div className={homeStyle.IconBox}>
                                                <div className={homeStyle.circle}></div>
                                                <div className={`${homeStyle.Icon} ${homeStyle.Icon5}`}></div>
                                            </div>
                                            <h3>开发设计</h3>
                                            <p>移动应用数据对接与开发</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <div className={homeStyle.IconBox}>
                                                <div className={homeStyle.circle}></div>
                                                <div className={`${homeStyle.Icon} ${homeStyle.Icon6}`}></div>
                                            </div>
                                            <h3>测试反馈</h3>
                                            <p>修改调整，规范完善</p>
                                        </a>
                                    </li>

                                    <li>
                                        <a>
                                            <div className={homeStyle.IconBox}>
                                                <div className={homeStyle.circle}></div>
                                                <div className={`${homeStyle.Icon} ${homeStyle.Icon7}`}></div>
                                            </div>
                                            <h3>跟进服务</h3>
                                            <p>后续维护，持续跟进</p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    //联系方式
    function contactSection() {
        return (
            <div className={homeStyle.contactContainer}>
                <div className={homeStyle.contactList}>
                    <div className={homeStyle.contactCard}>
                        <div className={homeStyle.contactCardCircle}>
                            <img src={require('../../images/position.png')}/>
                        </div>
                        <p>崇左市扶绥县山圩镇强山路58号</p>
                    </div>
                    <div className={homeStyle.contactCard}>
                        <div className={homeStyle.contactCardCircle}>
                            <img src={require('../../images/tel.png')}/>
                        </div>
                        <p>手机：18077170651</p>
                    </div>
                    <div className={homeStyle.contactCard}>
                        <div className={homeStyle.contactCardCircle}>
                            <img src={require('../../images/email.png')}/>
                        </div>
                        <p>邮箱：ALQ115@126.COM</p>
                    </div>
                    <div className={homeStyle.contactCard}>
                        <div className={homeStyle.contactCardCircle}>
                            <img src={require('../../images/wechat.png')}/>
                        </div>
                        <p>微信：ALQ115</p>
                    </div>
                </div>
            </div>
        )
    }

    //所有权
    function ownership() {
        return (
            <div className={homeStyle.ownerContainer}>
                <div className={homeStyle.ownerBox}>
                    <p><a href='https://beian.miit.gov.cn/' target="_blank" style={{"text-decoration":'none'}}>桂ICP备2023010452号-1</a></p>
                    <p>@2023 扶绥县嘉世网络科技工作室 版权所有</p>
                </div>
            </div>
        )
    }

    return (
        <div>
            {HeaderNavbar()}
            {Rotation()}
            {Solution()}
            {ServiceProcess()}
            {contactSection()}
            {ownership()}
        </div>
    )
}
