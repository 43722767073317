import React, {useState} from "react";
import homeStyle from "../home/index.module.css";
import {NavLink} from "react-router-dom";

export default function SmartCityScreen() {
    const [selectedItem, setSelectedItem] = useState('智慧城市')

    // 导航条
    function HeaderNavbar() {
        return (
            <nav className={homeStyle.headerNavbar}>
                <div className={homeStyle.logo}>
                    <img src={require('../../images/logo.png')}/>
                    <h3>嘉世工作室</h3>
                </div>
                <div className={homeStyle.navbarItems}>
                    <ul>
                        <NavLink to={"/"}>
                            <li onClick={() => setSelectedItem("首页")}
                                className={selectedItem === "首页" ? homeStyle.active : ""}>首页
                            </li>
                        </NavLink>
                        <NavLink to={"/smartCity"}>
                            <li onClick={() => setSelectedItem("智慧城市")}
                                className={selectedItem === "智慧城市" ? homeStyle.active : ""}>智慧城市
                            </li>
                        </NavLink>
                        <NavLink to={"/jiashiform"}>
                            <li onClick={() => setSelectedItem("嘉世表单")}
                                className={selectedItem === "嘉世表单" ? homeStyle.active : ""}>嘉世表单
                            </li>
                        </NavLink>
                        <NavLink to={"/customdevelopment"}>
                            <li onClick={() => setSelectedItem("开发定制")}
                                className={selectedItem === "开发定制" ? homeStyle.active : ""}>开发定制
                            </li>
                        </NavLink>
                        <NavLink to={"/contactus"}>
                            <li onClick={() => setSelectedItem("关于我们")}
                                className={selectedItem === "关于我们" ? homeStyle.active : ""}>关于我们
                            </li>
                        </NavLink>

                    </ul>
                </div>
            </nav>
        )
    }

    //联系方式
    function contactSection() {
        return (
            <div className={homeStyle.contactContainer}>
                <div className={homeStyle.contactList}>
                    <div className={homeStyle.contactCard}>
                        <div className={homeStyle.contactCardCircle}>
                            <img src={require('../../images/position.png')}/>
                        </div>
                        <p>崇左市扶绥县山圩镇强山路58号</p>
                    </div>
                    <div className={homeStyle.contactCard}>
                        <div className={homeStyle.contactCardCircle}>
                            <img src={require('../../images/tel.png')}/>
                        </div>
                        <p>手机：18077170651</p>
                    </div>
                    <div className={homeStyle.contactCard}>
                        <div className={homeStyle.contactCardCircle}>
                            <img src={require('../../images/email.png')}/>
                        </div>
                        <p>邮箱：ALQ115@126.COM</p>
                    </div>
                    <div className={homeStyle.contactCard}>
                        <div className={homeStyle.contactCardCircle}>
                            <img src={require('../../images/wechat.png')}/>
                        </div>
                        <p>微信：ALQ115</p>
                    </div>
                </div>
            </div>
        )
    }

    //所有权
    function ownership() {
        return (
            <div className={homeStyle.ownerContainer}>
                <div className={homeStyle.ownerBox}>
                    <p>桂ICP备2023010452号-1</p>
                    <p>@2023 扶绥县嘉世网络科技工作室 版权所有</p>
                </div>
            </div>
        )
    }

    return (
        <div>
            {HeaderNavbar()}
            {contactSection()}
            {ownership()}
        </div>
    )
}
